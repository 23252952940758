import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";

const Header = () => {
    console.log("Rendering Header");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                console.log("User logged in, currentUser:", currentUser.email);
                if (window.location.pathname === "/") {
                    navigate("/dashboard");
                }
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setEmail("");
            setPassword("");
            setIsDropdownOpen(false);
        } catch (error) {
            alert("Login failed: " + error.message);
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate("/");
        } catch (error) {
            alert("Logout failed: " + error.message);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bungee&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                rel="stylesheet"
            />
            <header className="bg-[#222831] text-white fixed top-0 w-full z-50 flex items-center justify-between px-6 py-6">
                <div
                    style={{ fontFamily: "'Bungee', cursive" }}
                    className="text-[#00ADB5] font-bold cursor-pointer flex items-baseline"
                    onClick={() => navigate("/")}
                >
                    <span className="text-4xl">A</span>
                    <span className="text-2xl">pp</span>
                    <span className="text-4xl">Q</span>
                    <span className="text-2xl">west</span>
                </div>

                <div className="flex items-center space-x-4">
                    {user ? (
                        <div className="flex items-center space-x-4">
                            <span className="text-sm font-medium">{user.email}</span>
                            <button
                                onClick={handleLogout}
                                className="px-4 py-2 bg-red-600 text-[#F3F4F6] rounded-lg hover:bg-red-700 transition"
                            >
                                Disconnect
                            </button>
                        </div>
                    ) : (
                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                className="px-4 py-2 bg-[#00ADB5] text-[#F3F4F6] rounded-lg hover:bg-[#007A8C] transition"
                            >
                                Login
                            </button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 bg-[#222831] border border-[#374151] rounded-lg shadow-lg p-4 w-64">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full px-4 py-2 mb-2 bg-[#374151] text-[#F3F4F6] rounded-lg focus:outline-none"
                                    />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full px-4 py-2 mb-2 bg-[#374151] text-[#F3F4F6] rounded-lg focus:outline-none"
                                    />
                                    <button
                                        onClick={handleLogin}
                                        className="w-full px-4 py-2 bg-[#00ADB5] text-[#F3F4F6] rounded-lg hover:bg-[#007A8C] transition"
                                    >
                                        Connect
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </header>
        </>
    );
};

export default React.memo(Header);
