import React from "react";

const Advertize = () => {
    return (
        <div className="p-6">
            <h1 className="text-4xl font-bold text-[#00ADB5]">Advertize</h1>
            <p className="mt-4 text-lg text-gray-700">
                This is the Advertize page. Use this section to describe advertising services or features.
            </p>
        </div>
    );
};

export default Advertize;