// src/pages/TermsOfUse.js
import React from "react";

const TermsOfUse = () => {
    return (
        <div className="pt-24 w-full text-white bg-[#222831] py-6 px-6 font-exo2">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-4xl font-bold text-center mb-8">Terms of Use</h1>
                <p className="text-sm text-gray-400 text-center mb-12">Effective Date: December 20, 2024</p>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Welcome</h2>
                    <p>
                        Welcome to AppQwest, a platform operated by AppQwest, located at 229, rue Saint-Honoré, 75001 Paris.
                        These Terms of Use (the “Agreement”) govern your access to and use of the services offered by AppQwest
                        (“we,” “our,” or “us”), including our website, mobile application, and associated content and features
                        (the “Services”). By accessing or using the Services, you agree to comply with these Terms of Use.
                        Please read them carefully before proceeding.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">1. Acceptance of Terms</h2>
                    <p>By accessing or using AppQwest, you confirm that you:</p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Are at least 18 years old or the legal age of majority in your jurisdiction.</li>
                        <li>Have read, understood, and agree to these Terms of Use and our Privacy Policy.</li>
                        <li>Will comply with all applicable laws and regulations.</li>
                    </ul>
                    <p>If you do not agree to these terms, you may not use the platform.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">2. Description of Services</h2>
                    <p>
                        AppQwest provides a gamified platform designed to reward users for engaging with mobile games and completing
                        interactive challenges (“Qwests”). Our platform includes the following features:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Game Discovery and Engagement: Personalized recommendations to discover engaging mobile games.</li>
                        <li>Interactive Qwests and Challenges: Opportunities to participate in daily challenges, leaderboards, and milestone events.</li>
                        <li>Reward Redemption: Earn points redeemable for gift cards and other digital rewards, subject to availability and eligibility.</li>
                    </ul>
                    <p>All rewards are offered at the sole discretion of AppQwest and may be subject to additional terms.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">3. Account Registration and Management</h2>
                    <p>To access our Services, users must create an account. By registering, you agree to:</p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Provide Accurate Information: Ensure that all information provided during registration is truthful, accurate, and up to date.</li>
                        <li>Maintain Account Security: Safeguard your login credentials and prevent unauthorized access to your account.</li>
                        <li>Notify AppQwest immediately if you suspect any unauthorized use of your account.</li>
                        <li>Abide by Usage Guidelines: Use your account solely for personal, non-commercial purposes and avoid creating multiple accounts or exploiting the platform for unauthorized gains.</li>
                    </ul>
                    <p>AppQwest reserves the right to suspend or terminate accounts that violate these terms.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">4. Rewards System</h2>
                    <h3 className="text-lg font-semibold mb-2">4.1 Earning Points</h3>
                    <ul className="list-disc list-inside pl-4">
                        <li>Completing specific Qwests and challenges within the platform.</li>
                        <li>Engaging with promotional offers and recommended games.</li>
                        <li>Participating in special events and milestone activities.</li>
                    </ul>
                    <h3 className="text-lg font-semibold mb-2">4.2 Redeeming Rewards</h3>
                    <ul className="list-disc list-inside pl-4">
                        <li>Reward Availability: Rewards are offered on a first-come, first-served basis and may vary depending on availability.</li>
                        <li>Verification Requirements: AppQwest reserves the right to verify user activity and eligibility before approving redemptions.</li>
                        <li>Expiration of Points: Points may expire after 180 days of inactivity on the platform.</li>
                        <li>Non-Transferability: Points and rewards are non-transferable and cannot be exchanged for cash unless explicitly specified.</li>
                        <li>Taxes and Legal Compliance: Users are responsible for reporting and paying any applicable taxes on rewards received.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">5. Prohibited Activities</h2>
                    <p>To maintain the integrity of the platform, users agree not to:</p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Use bots, emulators, scripts, or other automated tools to earn points or rewards.</li>
                        <li>Engage in fraudulent activities, including but not limited to creating fake accounts or providing false information.</li>
                        <li>Circumvent security measures, manipulate the platform, or interfere with the experience of other users.</li>
                        <li>Post or share offensive, harmful, or unlawful content within the platform.</li>
                        <li>Exploit vulnerabilities or reverse-engineer the platform’s systems for unauthorized gains.</li>
                    </ul>
                    <p>Violation of these terms may result in:</p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Forfeiture of earned points and rewards.</li>
                        <li>Suspension or termination of your account.</li>
                        <li>Legal action where applicable.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">6. Data Collection and Privacy</h2>
                    <p>AppQwest is committed to protecting your privacy and complying with applicable data protection laws, including GDPR. We collect and process the following types of data:</p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Personal Information: Information provided during registration, such as name, email address, and contact details.</li>
                        <li>Usage Data: Details of your interactions with the platform, including completed Qwests, earned rewards, and engagement metrics.</li>
                        <li>Device and Location Data: Information about the device you use to access the platform and approximate location data for fraud prevention and analytics.</li>
                        <li>Cookies and Tracking Technologies: AppQwest uses cookies to enhance user experience, analyze platform performance, and deliver personalized content. Users may manage cookie preferences via browser settings.</li>
                    </ul>
                    <p>For more details, refer to our Privacy Policy.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">7. Intellectual Property</h2>
                    <p>All content, trademarks, and materials provided through AppQwest are owned by AppQwest or its licensors. Users may not:</p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Copy, reproduce, distribute, or modify any content without prior written consent.</li>
                        <li>Reverse-engineer or attempt to extract source code or proprietary algorithms from the platform.</li>
                    </ul>
                    <p>Violation of these terms may result in legal action and immediate termination of access to the platform.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">8. Limitation of Liability</h2>
                    <p>
                        To the maximum extent permitted by law:
                        AppQwest provides the Services on an "as-is" and "as-available" basis without warranties of any kind.
                        AppQwest is not liable for:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Service interruptions, delays, or errors caused by external factors beyond our control.</li>
                        <li>Loss of data, points, or rewards due to unauthorized access or technical issues.</li>
                        <li>Damages resulting from reliance on third-party content or advertisements.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">9. Modifications to Terms and Services</h2>
                    <p>
                        AppQwest reserves the right to:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Modify these Terms of Use at any time, with updates communicated via email or platform notifications.</li>
                        <li>Add, remove, or modify features of the platform without prior notice.</li>
                        <li>Suspend or discontinue the Services for maintenance or other operational reasons.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">10. Termination of Account</h2>
                    <p>
                        AppQwest may suspend or terminate your account at its sole discretion for:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Breaches of these Terms of Use.</li>
                        <li>Prolonged inactivity exceeding 180 days.</li>
                        <li>Engagement in fraudulent, abusive, or unauthorized activities.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">11. Governing Law and Dispute Resolution</h2>
                    <p>
                        These Terms are governed by the laws of France. Any disputes arising from these terms will be resolved through:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Amicable Negotiation: Users are encouraged to contact AppQwest to resolve disputes informally.</li>
                        <li>Arbitration: If unresolved, disputes will be submitted to arbitration in Paris, France.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">12. Promotions and Contests</h2>
                    <p>
                        From time to time, AppQwest may offer promotions, contests, or special events. Participation is subject to specific rules, including:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>Eligibility requirements, such as location or age restrictions.</li>
                        <li>Deadlines for participation and reward redemption.</li>
                        <li>Verification of compliance with contest rules before awarding prizes.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">13. Third-Party Services and Advertisements</h2>
                    <p>
                        AppQwest may feature third-party services, advertisements, or links within the platform. Users acknowledge that:
                    </p>
                    <ul className="list-disc list-inside pl-4">
                        <li>AppQwest is not responsible for the content or accuracy of third-party services.</li>
                        <li>Interactions with third-party providers are governed by their respective terms and privacy policies.</li>
                        <li>AppQwest is not liable for damages arising from third-party interactions.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-xl font-semibold mb-4">14. Contact Information</h2>
                    <p>
                        For support, inquiries, or feedback, please contact us at:
                    </p>
                    <p>
                        AppQwest<br />
                        229, rue Saint-Honoré, 75001 Paris<br />
                        Email: info@appqwest.co
                    </p>
                </section>

                <footer className="mt-12 text-center">
                    <p className="text-sm text-gray-400">&copy; {new Date().getFullYear()} AppQwest. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default TermsOfUse;
