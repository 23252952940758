import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { doc, getDoc, collection, query, where, getDocs, setDoc } from "firebase/firestore";

const CreateChallenge = () => {
    const [apps, setApps] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [tasks, setTasks] = useState([{ name: "", subtext: "", points: "" }]);

    useEffect(() => {
    const fetchCompanyApps = async () => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) return;

            const uid = user.uid;

            // Fetch the user's companyId
            const userDocRef = doc(db, "users", uid);
            const userDocSnap = await getDoc(userDocRef);

            if (!userDocSnap.exists()) {
                throw new Error("User not found.");
            }

            const { companyId } = userDocSnap.data();

            // Query appList based on the companyId
            const appListQuery = query(collection(db, "appList"), where("companyId", "==", companyId));
            const querySnapshot = await getDocs(appListQuery);

            const companyApps = [];
            querySnapshot.forEach((doc) => {
                const appsMap = doc.data().apps || {}; // Ensure apps exist
                Object.values(appsMap).forEach((app) => {
                    companyApps.push(app); // Flatten each app object into the array
                });
            });

            setApps(companyApps);
        } catch (error) {
            console.error("Error fetching apps:", error);
        }
    };

    fetchCompanyApps();
}, []);

    const toggleDropdown = () => setDropdownOpen((prev) => !prev);

    const handleAppSelection = (appId) => {
        const selectedAppData = apps.find((app) => app.id === appId);
        setSelectedApp(selectedAppData);
        setDropdownOpen(false);
    };

    const handleTaskChange = (index, field, value) => {
        const updatedTasks = [...tasks];
        updatedTasks[index][field] = value;
        setTasks(updatedTasks);
    };

    const addTask = () => {
        setTasks([...tasks, { name: "", subtext: "", points: "" }]);
    };

    const saveChallenge = async () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
            alert("You need to be logged in to save a challenge.");
            return;
        }

        if (!selectedApp) {
            alert("Please select an app before saving the challenge.");
            return;
        }

        if (tasks.some((task) => !task.name || !task.points)) {
            alert("All tasks must have a name and points.");
            return;
        }

        try {
            const challengesRef = collection(db, "challenges");
            const challengesSnapshot = await getDocs(challengesRef);
            const nextChallengeId = `${challengesSnapshot.size + 1}`;

            const challengeData = {
                id: nextChallengeId,
                userId: user.uid,
                appId: selectedApp.id,
                tasks: tasks.map((task) => ({
                    ...task,
                    points: parseFloat(task.points),
                })),
            };

            const challengeDocRef = doc(db, "challenges", nextChallengeId);
            await setDoc(challengeDocRef, challengeData);

            alert("Challenge saved successfully!");
        } catch (error) {
            console.error("Error saving challenge:", error);
            alert("Failed to save challenge.");
        }
    };

    const filteredApps = apps.filter((app) =>
        app.title?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold mb-4">Create a New Challenge</h1>

            {/* App Selection */}
            <div className="mb-4 relative">
                <h2 className="font-semibold mb-2">Application</h2>
                <div
                    className="w-full px-4 py-2 border rounded bg-white cursor-pointer"
                    onClick={toggleDropdown}
                >
                    {selectedApp ? selectedApp.title : "Select an App"}
                </div>
                {dropdownOpen && (
                    <div className="absolute left-0 mt-1 w-full border rounded bg-white shadow-lg max-h-60 overflow-y-auto z-20">
                        {/* Search Input */}
                        <div className="p-2">
                            <input
                                type="text"
                                placeholder="Search apps..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        {/* Filtered App List */}
                        {filteredApps.map((app) => (
                            <div
                                key={app.id}
                                onClick={() => handleAppSelection(app.id)}
                                className={`flex items-center p-2 cursor-pointer hover:bg-gray-200 ${
                                    selectedApp?.id === app.id ? "bg-gray-200" : ""
                                }`}
                            >
                                <img
                                    src={app.icon || "https://via.placeholder.com/40"}
                                    alt={app.title}
                                    className="w-10 h-10 mr-2"
                                />
                                <div>
                                    <p className="font-semibold">{app.title}</p>
                                    <p className="text-sm text-gray-500">{app.developer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Tasks */}
            <h2 className="font-semibold mb-2">Tasks</h2>
            {tasks.map((task, index) => (
                <div key={index} className="mb-4 grid grid-cols-3 gap-4">
                    <input
                        type="text"
                        placeholder="Task Name"
                        value={task.name}
                        onChange={(e) => handleTaskChange(index, "name", e.target.value)}
                        className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="text"
                        placeholder="Subtext (Optional)"
                        value={task.subtext}
                        onChange={(e) => handleTaskChange(index, "subtext", e.target.value)}
                        className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <input
                        type="number"
                        placeholder="Points"
                        value={task.points}
                        onChange={(e) => handleTaskChange(index, "points", e.target.value)}
                        className="px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
            ))}

            {/* Add Task Button */}
            <button
                onClick={addTask}
                className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
            >
                + Add Task
            </button>

            {/* Save Challenge Button */}
            <div className="mt-6">
                <button
                    onClick={saveChallenge}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Save Challenge
                </button>
            </div>
        </div>
    );
};

export default CreateChallenge;
