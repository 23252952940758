import React from "react";
import { Outlet } from "react-router-dom";
import HeaderIndex from "./HeaderIndex";

const PublicLayout = () => {
    console.log("Rendering PublicLayout");

    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            <HeaderIndex />
            {/* Main Content */}
            <div className="flex-1 bg-gray-100">
                <Outlet />
            </div>
        </div>
    );
};

export default PublicLayout;
