import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaBullhorn } from "react-icons/fa";

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            {/* Correct Font Import */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link
                href="https://fonts.googleapis.com/css2?family=Bungee&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                rel="stylesheet"
            />

            <header
                className="bg-[#222831] text-white fixed top-0 w-full z-50 flex items-center justify-between px-6 py-6"
            >
                {/* Logo */}
                <div
                    className="text-[#00ADB5] font-bold cursor-pointer flex items-baseline"
                    onClick={() => navigate("/")}
                    style={{ fontFamily: "'Bungee', cursive" }}
                >
                    <span className="text-4xl">A</span>
                    <span className="text-2xl">pp</span>
                    <span className="text-4xl">Q</span>
                    <span className="text-2xl">west</span>
                </div>

                {/* Navigation Links */}
                <nav className="hidden md:flex gap-6 font-exo2 text-right">
                    <button
                        className="flex items-center gap-2 text-white hover:text-[#00ADB5] transition text-xl"
                        onClick={() => navigate("/advertize")}
                    >
                        <FaBullhorn /> Advertize
                    </button>
                </nav>

                {/* Dropdown Menu for Small Screens */}
                <div className="md:hidden relative font-exo2 text-right">
                    <button
                        className="text-white text-2xl focus:outline-none"
                        onClick={toggleDropdown}
                    >
                        <FaBars />
                    </button>
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-3 bg-[#222831] border border-[#374151] rounded-lg shadow-lg w-40 animate-fade-in">
                            <button
                                className="flex items-center gap-2 px-4 py-2 text-white hover:bg-[#374151] transition w-full text-right"
                                onClick={() => {
                                    setIsDropdownOpen(false);
                                    navigate("/advertize");
                                }}
                            >
                                <FaBullhorn /> Advertize
                            </button>
                        </div>
                    )}
                </div>
            </header>
            <style>
                {`
                    @keyframes fade-in {
                        from {
                            opacity: 0;
                            transform: translateY(-10%);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                    .animate-fade-in {
                        animation: fade-in 0.2s ease-in-out;
                    }
                `}
            </style>
        </>
    );
};

export default React.memo(Header);
